"use client";

import Message from "@/components/Message";
import type { API } from "@/hl-common/types/api/API";
import * as Sentry from "@sentry/nextjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ContextNotInitializedError from "./ContextNotInitilizedError";
import { ingestEvent } from "./api/client";
import { ApiError, getErrorMessage } from "./api/fetch";
import {
  deleteEvent,
  getNumLocalEvents,
  getRandomEvent,
  hasLocalCourseEvents,
  hasLocalModuleEvents,
  saveEvent,
} from "./localEvents";

export const EventContext = React.createContext({
  addEvent: (event: API.ingestEvent.body): void => {
    throw new ContextNotInitializedError();
  },
  hasQueuedModuleEvents: (moduleId: number): boolean => {
    throw new ContextNotInitializedError();
  },
  hasQueuedCourseEvents: (courseId: number): boolean => {
    throw new ContextNotInitializedError();
  },
  errors: {} as Record<string, string>,
  queueSize: 1,
});

export const WithEvent = ({
  userId,
  children,
}: { userId: number; children: React.ReactNode }) => {
  // The number of unsynced local events.
  //
  // Note that this depends on the user id, and is only meaningful when a user
  // ID is available. If there is no user, we conservatively assume that there
  // might be events.
  const [queueSize, setQueueSize] = useState(
    userId ? getNumLocalEvents(userId) : 1,
  );

  const [errors, setErrors] = useState({} as Record<string, string>);

  const deleteEventAndError = useCallback(
    (uuid: string) => {
      if (!userId) {
        return;
      }

      // clear from localStorage
      deleteEvent(userId, uuid);

      // clear from event errors
      setErrors((errors) => {
        const filtered = { ...errors };
        delete filtered[uuid];
        return filtered;
      });
    },
    [userId],
  );

  // Sends out events from localStorage, until local storage is empty
  const drainEvents = useCallback(async () => {
    if (!userId) {
      return;
    }

    let res: ReturnType<typeof getRandomEvent> = undefined;

    // biome-ignore lint/suspicious/noAssignInExpressions: Intentionally using a c-style while loop.
    while ((res = getRandomEvent(userId, setQueueSize))) {
      const { key, event } = res;
      try {
        await ingestEvent({ body: event });
      } catch (error) {
        console.error("Unable to sync event:", JSON.stringify(error));

        if (failedValidation(error) || isOld(event)) {
          Sentry.captureException(
            `Encountered an unsyncable event: ${JSON.stringify(event, null, 2)}`,
          );
        } else {
          // Something unknown is preventing us from syncing events. Stop trying
          // for now, and keep the event in localStorage.
          setErrors((errors) => ({
            ...errors,
            [key]: getErrorMessage(error),
          }));
          return;
        }
      }

      // At this point, we've made some progress: either ingestEvent has
      // succeeded, or we've encountered a permanent error that means we should
      // drop the event. Thus, delete the event and continue.
      deleteEventAndError(key);
    }
  }, [userId, deleteEventAndError]);

  // add event to queue, and attempt send immediately
  const addEvent = useCallback(
    (event: API.ingestEvent.body) => {
      if (!userId) {
        return;
      }

      // save it to localstorage
      saveEvent(userId, event);

      // attempt to drain the queue
      drainEvents();
    },
    [userId, drainEvents],
  );

  // Checks whether any queued event is relevant to the given module
  const hasQueuedModuleEvents = useCallback(
    (moduleId: number) => {
      if (!userId) {
        // Conservatively assume that, once a user is logged in, there might be
        // queued events.
        return true;
      }
      return hasLocalModuleEvents(userId, moduleId);
    },
    [userId],
  );

  // Checks whether any queued event is relevant to the given course overview screen
  const hasQueuedCourseEvents = useCallback(
    (courseId: number) => {
      if (!userId) {
        // Conservatively assume that, once a user is logged in, there might be
        // queued events.
        return true;
      }
      return hasLocalCourseEvents(userId, courseId);
    },
    [userId],
  );

  // Attempt to send events when the component mounts, when online status
  // changes, when the user refocuses this tab, or when the user changes.
  useEffect(() => {
    setQueueSize(userId ? getNumLocalEvents(userId) : 1);
    drainEvents();

    window.addEventListener("online", drainEvents);
    window.addEventListener("focus", drainEvents);

    return () => {
      window.removeEventListener("online", drainEvents);
      window.removeEventListener("focus", drainEvents);
    };
  }, [userId, drainEvents]);

  return (
    <EventContext.Provider
      value={{
        addEvent,
        hasQueuedModuleEvents,
        hasQueuedCourseEvents,
        errors,
        queueSize,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

// error parsers
const failedValidation = (error: unknown) => {
  return error instanceof ApiError && error.statusCode === 400;
};

const isOld = (evt: API.ingestEvent.body) => {
  const twoWeeksAgo = new Date(
    Date.now() - 2 * 7 * 24 * 60 * 60 * 1000,
  ).toISOString();
  return evt.timestamp && evt.timestamp < twoWeeksAgo;
};

// DisplayEventErrors displays errors that occur while saving events in the EventContext.
export const DisplayEventErrors = () => {
  const { errors } = useContext(EventContext);
  if (!errors || Object.keys(errors).length === 0) {
    return null;
  }

  // convert them to a hash of error -> count of that error
  const errorsByCount = {} as Record<string, number>;
  for (const value of Object.values(errors)) {
    errorsByCount[value] = (errorsByCount[value] || 0) + 1;
  }

  return (
    <Message warning>
      <p>
        We&apos;re having difficulty saving your answers. Your progress may be
        lost if you don&apos;t reconnect.
      </p>
      {Object.keys(errorsByCount).map((key) => (
        <code className="block mb-1" key={key}>
          {key}: {errorsByCount[key]}
        </code>
      ))}
    </Message>
  );
};
